<!-- <div class="kt-login__body">
        <div class="kt-login__form">
            <div class="kt-login__title">
                <h3>{{ 'AUTH.RESET.TITLE' | translate }}</h3>
            </div>
    
            <kt-auth-notice></kt-auth-notice>
    		<form class="" [formGroup]="resetPasswordForm" (ngSubmit)='submitResetPassword(resetPasswordForm)'>

                <div class="form-group">
                    <mat-form-field>
                        <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
                        <input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off"/>
                        <small *ngIf="checkPassword" class="text-danger">
                                {{'MANAGE.USER.MESSAGE.PASS_MIN_CHAR' | translate}}
                            </small>
                        <mat-error *ngIf="isControlHasError('email','required')">
                            <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('email','email')">
                            <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('email','minLength')">
                            <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                        </mat-error>
                        <mat-error *ngIf="isControlHasError('email','maxLength')">
                            <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">
                        <mat-form-field>
                            <mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
                            <input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}" formControlName="passAgain" autocomplete="off"/>
                            <mat-error *ngIf="isControlHasError('email','required')">
                                <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('email','email')">
                                <strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('email','minLength')">
                                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('email','maxLength')">
                                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                <div class="kt-login__actions">
                    <button type="button" routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-secondary btn-elevate kt-login__btn-secondary">{{ 'AUTH.GENERAL.SIGNIN' | translate }}</button>
                    <button 
                     type="submit"
                     [disabled]="resetPasswordForm.invalid" 
                     class="btn btn-primary btn-elevate kt-login__btn-primary"
                     [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
    
     -->

     <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v1" id="kt_login">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div class="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
                [ngStyle]="backgroundImageStyle">
                <div class=" select-default-language kt-align-right">
    
                    <div ngbDropdown placement="bottom-right" class="kt-header__topbar-item kt-header__topbar-item--langs">
                        <div ngbDropdownToggle class="kt-header__topbar-wrapper">
                            <span class="kt-header__topbar-icon" style="cursor: pointer;">
                                <img class="" src="{{language?.flag}}" alt="" /> <span>{{language.name}}</span>
                            </span>
                        </div>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
                            <ul class="kt-nav kt-margin-t-10 kt-margin-b-10">
                                <ng-container *ngFor="let language of languages">
                                    <li class="kt-nav__item" [ngClass]="{'kt-nav__item--active': language.active}">
                                        <a ngbDropdownToggle href="javascript:;" (click)="setLanguage(language.lang)"
                                            [ngClass]="{'kt-nav__link--active': language.active}" class="kt-nav__link">
                                            <span class="kt-nav__link-icon">
                                                <img src="{{language.flag}}">
                                            </span>
                                            <span class="kt-nav__link-text">{{language.name}}</span>
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
    
                <div class="login-body">
                    <div class="kt-login__body">
                        <div class="kt-login__form">
                            <div class="kt-login__logo" style="text-align: center;">
                                <a href="javascript:;" class="">
                                    <img [src]="logo" alt="" style="max-width: 100px;max-height: 150px;">
                                </a>
                            </div>
                            <div class="kt-login__title">
                                <h3 class="login-title">{{ 'AUTH.RESET.TITLE' | translate }}</h3>
                            </div>
    
                            <kt-auth-notice></kt-auth-notice>
                            <form [formGroup]="resetPasswordForm" novalidate="novalidate">
                                <div class="form-group validate is-invalid">
                                    <label class="label-login">{{ 'MANAGE.USER.GENERAL.NEW_PASSWORD' | translate }}</label>
                                    <input type="password" formControlName="newPassword" name="newPassword" autocomplete="off" class="form-control input-login" placeholder="{{ 'MANAGE.USER.GENERAL.NEW_PASSWORD' | translate }}"/>
                                    <div class="error invalid-feedback" *ngIf="isControlHasError('newPassword','required')">
                                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                    </div>
                                    <div class="error invalid-feedback" *ngIf="isControlHasError('newPassword','minlength')">
                                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 6</strong>
                                    </div>
                                    <div class="error invalid-feedback" *ngIf="isControlHasError('newPassword','maxlength')">
                                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 50</strong>
                                    </div>
                                </div>
                                <div class="form-group validate is-invalid">
                                    <label class="label-login">{{ 'MANAGE.USER.GENERAL.COMFIRM_PASSWORD' | translate }}</label>
                                    <input type="password" formControlName="confirmPassword" name="confirmPassword" autocomplete="off" class="form-control input-login" placeholder="{{ 'MANAGE.USER.GENERAL.COMFIRM_PASSWORD' | translate }}"/>
                                    <div class="error invalid-feedback" *ngIf="isControlHasError('confirmPassword','required')">
                                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                                    </div>
                                    <div class="error invalid-feedback" *ngIf="isControlHasError('confirmPassword','minlength')">
                                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 6</strong>
                                    </div>
                                    <div class="error invalid-feedback" *ngIf="isControlHasError('confirmPassword','maxlength')">
                                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 50</strong>
                                    </div>
                                    <div class="error invalid-feedback" *ngIf="passwordMatchValidator()">
                                        <strong>{{ 'MANAGE.USER.MESSAGE.PASSWORD_INCORRECT' | translate }}</strong>
                                    </div>
                                </div>
                            </form>
    
                            <div class="kt-login__actions">
                                <button type="button" routerLink="/auth/login" [disabled]="loading" id="kt_login_signup_cancel" class="btn btn-primary btn-elevate kt-login__btn-primary"
                                style="margin-right: 15px;">
                                    {{ 'AUTH.GENERAL.BACK_BUTTON' | translate }}
                                </button>
                                <button 
                                 type="submit" (click)="submitResetPassword()"
                                 id="kt_login_signin_submit" 
                                 [disabled]="resetPasswordForm.invalid"
                                 class="btn btn-primary btn-elevate kt-login__btn-primary"
                                 [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>