
// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Auth
import { ActivatedRoute } from '@angular/router';
import { AuthNoticeService, AuthService } from '../../../@core/auth';
import { LayoutConfigService, ToastService, ValidatorCustomService } from '../../../@core/layout';
import { LoginPage } from '../../../@core/auth/_models/login-page';
import { TranslationService } from '../../../@core/services/translation.service';

interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
}

@Component({
	selector: 'kt-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	// Public params
    resetPasswordForm: FormGroup;
    public checkPassword : boolean = false;
	loading = false;
    errors: any = [];
    public token:string = '';
	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	public loginPage: LoginPage;
	public backgroundImageStyle:any;
	public backgroundImage:string = './assets/media/bg/bg-3.jpg';
	public logo:string;
	language: LanguageFlag;

	languages: LanguageFlag[] = [
		{
			lang: 'vn',
			name: 'Việt Nam',
			flag: './assets/media/flags/001-vietnam.svg'
		},
		{
			lang: 'en',
			name: 'English',
			flag: './assets/media/flags/012-uk.svg'
		}
	];

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private toast: ToastService,
        private validate:ValidatorCustomService,
		private layoutConfigService: LayoutConfigService,
		private translationService: TranslationService,
	) {
        this.unsubscribe = new Subject();
        this.token = this.route.snapshot.queryParamMap.get('token');
		this.logo =  layoutConfigService.getLogo();
		this.loginPage = this.layoutConfigService.getLoginPage();
		if(this.loginPage.background && this.loginPage.background.length > 0){
			this.backgroundImage = this.loginPage.background[0]
			this.backgroundImageStyle = { 'background-image': 'url(' + this.backgroundImage + ')',
			'background-position': 'center',
			'background-repeat': 'no-repeat',
			'background-size': 'cover',
			'height':'100%' }
		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.initRegistrationForm();
		this.setSelectedLanguage();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.resetPasswordForm = this.fb.group({
			newPassword: [null,[Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
            confirmPassword : [null,[Validators.required, Validators.minLength(6), Validators.maxLength(50)]]
		});
    }
    
    isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.resetPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}
		return control.hasError(validationType) && (control.dirty || control.touched);
	}

	passwordMatchValidator(): boolean {
		let form = this.resetPasswordForm;
		const password = form.value.newPassword;
		const confirmPassword = form.value.confirmPassword;
		const control = this.resetPasswordForm.controls['confirmPassword'];
		if (password !== confirmPassword && (control.dirty || control.touched)) {
			return true;
		}
		return false;
	  }
	/**
	 * Form Submit
	 */
	submitResetPassword() {
		let form = this.resetPasswordForm;
        let confirmPassword = form.value.confirmPassword;

        let params = {
            newPassword : confirmPassword,
            token : this.token
		}
      
		this.authService.resetPassword(params, { notifyGlobal: true }).pipe(
        finalize(() => {
          this.cdr.markForCheck();
        })).subscribe((result: any) => {
        	if (result.errorCode == "200") {
          		this.router.navigate(["/auth/login"]);
        	}
      	});
	}

	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;

			} else {
				language.active = false;
			}
		});
		this.translationService.setDefaultLang(lang);
		this.translationService.setLanguage(lang);
	}

	
	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}

}
