import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {NbDialogRef} from "@nebular/theme";
import { AuthService, currentUser } from '../../../@core/auth';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../@core/reducers';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  saveForm: FormGroup;
  private unsubscribe: Subject<any> = new Subject();
  constructor(
    private fb: FormBuilder,
    protected dialogRef: NbDialogRef<ChangePasswordComponent>,
    private authService: AuthService,
    private store: Store<AppState>,
  ) {
    this.saveForm = this.fb.group({
      oldPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
      newPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
      userId: [null, Validators.required],
    });
    this.store.pipe(select(currentUser)).subscribe((user: any) => {
      if(user){
        this.saveForm.patchValue({userId: user.id});
      }
    });
  }
  
  ngOnInit() {
  }

  ngOnDestroy(){
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onSubmit(form: any) {
    if (this.saveForm.invalid) {
      return;
    }
    const params = {
      passwordOld: form.value.oldPassword,
      passwordNew: form.value.newPassword,
      userId: form.value.userId,
    } as any;

    this.authService.changePassword(params, { notifyGlobal: true }).takeUntil(this.unsubscribe).subscribe((result: any) => {
      if (result.errorCode === '200') {
        this.dialogRef.close(1);
      }
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.saveForm.controls[controlName];
		if (!control) {
			return false;
		}
		return control.hasError(validationType) && (control.dirty || control.touched);
	}

	passwordMatchValidator(): boolean {
		let form = this.saveForm;
		const password = form.value.newPassword;
		const confirmPassword = form.value.confirmPassword;
		const control = this.saveForm.controls['confirmPassword'];
		if (password !== confirmPassword && (control.dirty || control.touched)) {
			return true;
		}
		return false;
	  }
}
