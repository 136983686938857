import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NbAccordionComponent, NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthService, Logout, PermissionActionTypes, currentUser} from '../../../@core/auth';
import {Store, select} from '@ngrx/store';
import {AppState} from '../../../@core/reducers';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { UserManageService } from '../../../@core/services/user-manage.service';
import { TranslationService } from '../../../@core/services/translation.service';
import { LayoutConfigService } from '../../../@core/layout';
import { PopupNotificationComponent } from '../popup-notification/popup-notification.component';
import { NotificationPopUpService } from '../../../shared/services/notification.service';
import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CurrentUserService } from '../../../shared/services/current-user.service';
import { BookingDetailComponent } from '../../../pages/report/booking-history/booking-detail/booking-detail.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  private notiService: NotificationPopUpService;
  userPictureOnly: boolean = false;
  user: any;
  listMessage : any = [];
  disableLoadNext : boolean = false;

  pageToLoadNext = 0;
  isDropdownVisible = false;


  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    }
  ];

  currentTheme = 'default';

  userMenu = [{title: this.translateService.instant('MANAGE.GENERAL.USER_INFO'), action: 'profile', keyTitle: 'MANAGE.GENERAL.USER_INFO'},
    {title: this.translateService.instant('COMMON.GENERAL.CHANGE_PASSWORD'), action: 'change-password', keyTitle: 'COMMON.GENERAL.CHANGE_PASSWORD'},
   {title: this.translateService.instant('COMMON.GENERAL.SIGN_OUT'), action: 'logout', keyTitle: 'COMMON.GENERAL.SIGN_OUT'}];

  title = 'S.TOUCH';
  idUser : any;
  currentUserService: CurrentUserService;
  selectedItem: any;
  @ViewChild('item') accordion;


  items = [
    { title: 'Item 1', description: 'Description for Item 1' }
  ];
  @ViewChild('notification') notification!: PopupNotificationComponent;
   private unsubscribe: Subject<any>;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private store: Store<AppState>,
              private translateService: TranslateService,
              private dialogService: NbDialogService,
              private authService: AuthService,
              private userManage: UserManageService,
              private cdr: ChangeDetectorRef,
              private translationService: TranslationService,
              private layoutConfigService: LayoutConfigService,
              private _notiService: NotificationPopUpService,
              private datePipe: DatePipe,
              private _currentUserService: CurrentUserService,
              ) {
                this.notiService = _notiService,
                this.currentUserService = _currentUserService,
                this.unsubscribe = new Subject();
  }

  ngOnInit() {
    this.showMessage();
    this.currentTheme = this.themeService.currentTheme;
    var loginPage = this.layoutConfigService.getLoginPage();
    this.title = loginPage.title;
    this.store.pipe(select(currentUser)).subscribe((user: any) => {
      if(user){
        this.user = user;
        this.user.walletBalanceStr = this.translateService.instant('MENU.BALANCE') + ': ' + new Intl.NumberFormat('en-US').format(this.user.walletBalance) + ' VNĐ';
      }
    });

    this.userManage.walletBalanceObservable$.subscribe((data: any) => {
      if(data && data.walletBalance){
        this.user.walletBalanceStr = this.translateService.instant('MENU.BALANCE') + ': ' + new Intl.NumberFormat('en-US').format(data.walletBalance) + ' VNĐ';
        this.cdr.detectChanges();
      }
    })

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick().pipe(takeUntil(this.destroy$)).subscribe((input: any) => {
      switch (input.item.action) {
        case 'logout':
          this.logout();
          break;
        case 'profile':
          this.openProfile();
          break;
        case 'change-password':
          this.openChangePassword();
          break;
      }
    })

    this.translationService.eventChange.subscribe((lang:any) => {
      if(this.user && this.user.walletBalance){
        this.user.walletBalanceStr = this.translateService.instant('MENU.BALANCE') + ': ' + new Intl.NumberFormat('en-US').format(this.user.walletBalance) + ' VNĐ';
      }
      this.userMenu.forEach(item => {
        item.title = this.translateService.instant(item.keyTitle)
      })
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  showNotification(item: any) {
    this.showMessage();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  logout() {
    this.store.dispatch(new Logout());
    if (localStorage.getItem("favorite-report") != null) {
      localStorage.removeItem('favorite-report');
    }
    if (localStorage.getItem("permissions") != null) {
      localStorage.removeItem('permissions');
    }
    if (localStorage.getItem("eventType") != null) {
      localStorage.removeItem('eventType');
    }
  }

  openProfile(item?: any) {
    const ref = this.dialogService.open(UserProfileComponent, {
      autoFocus: false,
      context: {
        // user: this.user,
      },
    });
  }

  openChangePassword() {
    const ref = this.dialogService.open(ChangePasswordComponent, {
      autoFocus: false,
      context: {
      },
    });
  }

  showMessage() {
    if(!this.idUser) {
      this.getUserId();
      return;
    }
    const options = {
      userId: this.idUser
    };

    const params = new HttpParams()
    .set('page', this.pageToLoadNext)
    .set('size', '10')
    .set('sort', 'createdAt.desc');
    this.notiService.search(options, params).subscribe((res) => {
      res.data.content.forEach(mess => {
        this.listMessage.push(mess)
      });
    }
  )

  }

  async getUserId() {
    await this._currentUserService
        .getCurrentUser()
        .then((data: any) => {
            this.idUser = data.id;
            this.showMessage();
        });
}
    toggle() {
      this.accordion.toggle();
    }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  
  handleClick(item) {
    if(item.isRead == 1) {
      var dataCharge = JSON.parse(item.data)
      var bookingId = dataCharge.bookingId;
      this.detail(bookingId)
    }else if(item.isRead == 0) {
      this.updateStatus(item)
    }
  }

  detail(bookingId: number) {
    const ref = this.dialogService.open(BookingDetailComponent, {
      autoFocus: false,
      context: {
        bookingId: bookingId
      },
    });
    ref.onClose.takeUntil(this.unsubscribe).subscribe();
  }

  loadNext() {

    const options = {
      userId: this.idUser
    };

    const params = new HttpParams()
      .set('page', this.pageToLoadNext)
      .set('size', '5')
      .set('sort', 'createdAt.desc');
    this.notiService.search(options, params).subscribe((res) => {
      if (this.pageToLoadNext + 1 < res.data.totalPages) {
        console.log(this.pageToLoadNext)
        console.log(res.data.totalPages)
        this.pageToLoadNext++;
        res.data.content.forEach(mess => {
          this.listMessage.push(mess)
        });
      } else {
        this.disableLoadNext = true;
      }
    }
    )
  }

  updateStatus(item) {
    if (item.isRead == 1) {
      return
    } else {

      const index = this.listMessage.findIndex(msg => msg.id === item.id);

      this.notiService.updateStatus(item.id).subscribe(
        res => {
          const index = this.listMessage.findIndex(msg => msg.id === item.id);
          this.listMessage[index].isRead = 1;
        },
        error => console.log(error)
      )
    }
  }

  trackById(index: number, item: any): number {
    return item.isRead; // Trả về id hoặc thuộc tính duy nhất khác
  }
}
