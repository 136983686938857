import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'MENU.ADMIN',
    icon: { icon: 'user', pack: 'fas'},
    data: {role: 'ROLE_menu.user_tree', user_tree: true},
  },
  {
    title: 'MENU.DASHBOARD',
    icon: { icon: 'home', pack: 'fas'},
    link: '/dashboard',
    home: true,
    data: {pageMain: true, role: 'ROLE_menu.dashboard'}
  },
  {
    title: 'MENU.MAP',
    icon: { icon: 'map', pack: 'fas'},
    link: '/map',
    data: {pageMain: true, role: 'ROLE_menu.map'}
  },
  {
    title: 'MENU.ESTIMATED_COST',
    icon: { icon: 'chart-bar', pack: 'fas'},
    link: '/report/estimate-cost',
  },
  {
    title: 'MENU.MANAGE',
    icon: { icon: 'cog', pack: 'fa'},
    data: {role: 'ROLE_menu.manage'},
    children: [
      {
        title: 'MENU.MANAGER_USER',
        link: '/manage/user',
        data: {role: 'ROLE_menu.user'},
      },
      {
        title: 'MENU.CHARGE_STATION',
        children: [{
          title: 'MENU.MAP',
          link: '/manage/charge-station/map',
        },{
          title: 'MENU.INFO',
          link: '/manage/charge-station/info',
        }],
        data: {role: 'ROLE_menu.charge_station'},
      },
      {
        title: 'MENU.CONNECTOR',
        link: '/manage/connector',
        data: {role: 'ROLE_menu.connector'},
      },
      {
        title: 'MENU.RFID_TAG',
        link: '/manage/rfid-tag',
        data: {role: 'ROLE_menu.rfid_tag'},
      },
      // {
      //   title: 'MENU.WALLET',
      //   data: {role: 'ROLE_menu.wallet'},
      // },
      {
        title: 'MENU.SERVICE_PRICE',
        link: '/manage/service-price',
        data: {role: 'ROLE_menu.service_price'},
      },
    ],
  },
  {
    title: 'MENU.REPORT',
    icon: { icon: 'chart-line', pack: 'fas'},
    data: {role: 'ROLE_menu.report'},
    children: [
      {
        title: 'MENU.CHARGING_HISTORY',
        link: '/report/charging-history',
        data: {role: 'ROLE_menu.charging_history'},
      },
      {
        title: 'MENU.BOOKING_HISTORY',
        link: '/report/booking-history',
        data: {role: 'ROLE_menu.booking_history'},
      },
      {
        title: 'MENU.CHANGE_BALANCE',
        link: '/report/change-balance',
        data: {role: 'ROLE_menu.change_balance'},
      },
      {
        title: 'MENU.DATA_DISTRIBUTE',
        link: '/report/data-distibute/view',
        data: {role: 'ROLE_menu.heatmap'},
      },
      {
        title: 'MENU.DATA_SYNTHESIS',
        link: '/report/data-synthesis',
        data: {role: 'ROLE_menu.data_synthesis'},
      },
      // {
        // title: 'MENU.REPAIR',
        // link: '/manage/user',
        // data: {role: 'ROLE_menu.repair'},
      // },
    ],
  },
  {
    title: 'MENU.SYSTEM_CONFIGURATION',
    icon: { icon: 'database', pack: 'fas'},
    data: {role: 'ROLE_menu.system_configuration'},
    children: [
      {
        title: 'MENU.ROLE',
        link: '/manage/role',
        data: {role: 'ROLE_menu.role'},
      },
      {
        title: 'MENU.PERMISSION',
        link: '/manage/permission',
        data: {role: 'ROLE_menu.permission'},
      },
      {
        title: 'MENU.LOGIN_PAGE',
        link: '/manage/login-page',
        data: {role: 'ROLE_menu.login_page'},
      },
      {
        title: 'MENU.SIM_TYPE',
        link: '/manage/sim-type',
        data: {role: 'ROLE_menu.sim_type'},
      },
    ],
  },
  {
    title: 'MENU.DEVICE_CONFIGURATION',
    icon: { icon: 'tools', pack: 'fas'},
    data: {role: 'ROLE_menu.device_configuration'},
    children: [
      {
        title: 'MENU.DEVICE_TYPE',
        link: '/manage/device-type',
        data: {role: 'ROLE_menu.device_type'},
      },
      // {
      //   title: 'MENU.DEVICE_ICON',
      //   link: '/manage/user',
      //   data: {role: 'ROLE_menu.device_icon'},
      // },
      {
        title: 'MENU.CONNECTOR_TYPE',
        link: '/manage/connector-type',
        data: {role: 'ROLE_menu.connector_type'},
      },
      // {
      //   title: 'MENU.CONNECTOR_ICON',
      //   link: '/manage/user',
      //   data: {role: 'ROLE_menu.connector_icon'},
      // },
    ],
  },
  {
    title: 'MENU.TECHNICAL_ASSISTANCE',
    icon: { icon: 'file-alt', pack: 'fas'},
    data: {role: 'ROLE_menu.technical_assistance'},
    children: [
      // {
      //   title: 'MENU.RAW',
        // link: '/manage/user',
      //   data: {role: 'ROLE_menu.raw'},
      // },
      // {
      //   title: 'MENU.GATEWAY_CONNECTION',
        // link: '/manage/user',
      //   data: {role: 'ROLE_menu.gateway_connection'},
      // },
    ],
  },
];
