// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
// Translate
import { TranslateService } from '@ngx-translate/core';
import { AuthNoticeService, AuthService } from '../../../@core/auth';
import { LoginPage } from '../../../@core/auth/_models/login-page';
import { LayoutConfigService } from '../../../@core/layout';
import { TranslationService } from '../../../@core/services/translation.service';
// Auth
interface LanguageFlag {
	lang: string;
	name: string;
	flag: string;
	active?: boolean;
}
@Component({
	selector: 'kt-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
	// Public params
	forgotPasswordForm: FormGroup;
	loading = false;
	errors: any = [];
	public loginPage: LoginPage;
	public backgroundImageStyle:any;
	public backgroundImage:string = './assets/media/bg/bg-3.jpg';
	public logo:string;

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	language: LanguageFlag;

	languages: LanguageFlag[] = [
		{
			lang: 'vn',
			name: 'Việt Nam',
			flag: './assets/media/flags/001-vietnam.svg'
		},
		{
			lang: 'en',
			name: 'English',
			flag: './assets/media/flags/012-uk.svg'
		},
		// {
		// 	lang: 'es',
		// 	name: 'Spanish',
		// 	flag: './assets/media/flags/016-spain.svg'
		// },
	];

	/**
	 * Component constructor
	 *
	 * @param authService
	 * @param authNoticeService
	 * @param translate
	 * @param router
	 * @param fb
	 * @param cdr
	 */
	constructor(
		private authService: AuthService,
		public authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private layoutConfigService: LayoutConfigService,
		private translationService: TranslationService,
	) {
		this.logo =  layoutConfigService.getLogo();
		this.loginPage = this.layoutConfigService.getLoginPage();
		if(this.loginPage.background && this.loginPage.background.length > 0){
			this.backgroundImage = this.loginPage.background[0]
			this.backgroundImageStyle = { 'background-image': 'url(' + this.backgroundImage + ')',
			'background-position': 'center',
			'background-repeat': 'no-repeat',
			'background-size': 'cover',
			'height':'100%' }
		}
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		console.log(window.location)
		this.initRegistrationForm();
		this.setSelectedLanguage();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
	

		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegistrationForm() {
		this.forgotPasswordForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submitForgotPassword() {
		let params = {
			email:this.forgotPasswordForm.value.email,
			domain:window.location.hostname
		};
		this.loading = true;
		this.authService.requestPassword(params,{ notifyGlobal: true, })
		.pipe(
			tap((result:any)=>{
				if(result.errorCode == '200') this.loading = false;
			}),
			finalize(() => {
				this.loading = false;
				this.cdr.markForCheck();
			})
		)
		.subscribe();
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.forgotPasswordForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

	// language
	setLanguage(lang) {
		this.languages.forEach((language: LanguageFlag) => {
			if (language.lang === lang) {
				language.active = true;
				this.language = language;

			} else {
				language.active = false;
			}
		});
		this.translationService.setDefaultLang(lang);
		this.translationService.setLanguage(lang);
	}

	
	setSelectedLanguage(): any {
		this.setLanguage(this.translationService.getSelectedLanguage());
	}
}
