<form [formGroup]="saveForm" (ngSubmit)="onSubmit(saveForm)">
    <nb-card class="modal-card">
        <nb-card-header>
            <div class="d-flex justify-content-between">
                <h5>
                    {{ 'MANAGE.USER.GENERAL.CHANGE_PASSWORD' | translate }}
                </h5>
                <button (click)="dialogRef.close()" class="close" nbButton ghost status="basic"><nb-icon
                        icon="close-outline"></nb-icon></button>
            </div>
        </nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="form-group col-lg-12 col-md-12">
                    <label class="form-control-file">{{ 'MANAGE.USER.GENERAL.OLD_PASSWORD' | translate }} <span class="text-danger">*</span></label>
                    <input type="password" nbInput fullWidth formControlName="oldPassword">
                    <div class="error invalid-feedback" *ngIf="isControlHasError('oldPassword','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </div>
                    <div class="error invalid-feedback" *ngIf="isControlHasError('oldPassword','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 6</strong>
                    </div>
                    <div class="error invalid-feedback" *ngIf="isControlHasError('oldPassword','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 50</strong>
                    </div>
                </div>
                <div class="form-group col-lg-12 col-md-12">
                    <label class="form-control-file">{{ 'MANAGE.USER.GENERAL.NEW_PASSWORD'|translate }} <span
                            class="text-danger">*</span></label>
                    <input type="password" nbInput fullWidth formControlName="newPassword">
                    <div class="error invalid-feedback" *ngIf="isControlHasError('newPassword','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </div>
                    <div class="error invalid-feedback" *ngIf="isControlHasError('newPassword','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 6</strong>
                    </div>
                    <div class="error invalid-feedback" *ngIf="isControlHasError('newPassword','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 50</strong>
                    </div>
                </div>
                <div class="form-group col-lg-12 col-md-12">
                    <label class="form-control-file">{{ 'MANAGE.USER.GENERAL.COMFIRM_PASSWORD'|translate }} <span
                            class="text-danger">*</span></label>
                    <input type="password" nbInput fullWidth formControlName="confirmPassword">
                    <div class="error invalid-feedback" *ngIf="isControlHasError('confirmPassword','required')">
                        <strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
                    </div>
                    <div class="error invalid-feedback" *ngIf="isControlHasError('confirmPassword','minlength')">
                        <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 6</strong>
                    </div>
                    <div class="error invalid-feedback" *ngIf="isControlHasError('confirmPassword','maxlength')">
                        <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 50</strong>
                    </div>
                    <div class="error invalid-feedback" *ngIf="passwordMatchValidator()">
                        <strong>{{ 'MANAGE.USER.MESSAGE.PASSWORD_INCORRECT' | translate }}</strong>
                    </div>
                </div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="justify-content-end d-flex">
                <button [status]="'primary'" type="submit" nbButton
                    [disabled]="saveForm.invalid" class="mr-2">
                    {{ 'COMMON.ACTIONS.SAVE_CHANGES' | translate }}
                </button>
                <button type="button" nbButton class="btn btn-secondary" (click)="dialogRef.close()">{{
                    'COMMON.ACTIONS.CANCEL' | translate }}
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</form>