import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { Role } from '../_models/role.model';
import { catchError, map, tap, takeUntil, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { currentUser } from '../_selectors/auth.selectors';
import { AppState } from '../../reducers';
import { Logout } from '../_actions/auth.actions';
import { ResponseBody } from '../../models/query-models/_response-body.model';
import { environment } from '../../../../environments/environment';
import { BaseOptionAPI } from '../../models/query-models/_base.option-api';
import { QueryParamsModel } from '../../models/query-models/query-params.model';
import { QueryResultsModel } from '../../models/query-models/query-results.model';
import { RoleSearchRequest } from '../../models/request/role-search-request';
import { TypesUtilsService } from '../../models/utils/types-utils.service';
import { Response } from '../../models/query-models/_new-response-body.model';
import { ConfigService } from '../../../shared/services/config.service';


// import { RSA_X931_PADDING } from 'constants';

const API_USERS_URL = 'api/users';
const API_PERMISSION_URL = 'permissions';
const API_ROLES_URL = 'api/roles';

@Injectable()
export class AuthService {
    public profileLoaded:EventEmitter<any> = new EventEmitter();
    constructor(
        private http: HttpClient, private store: Store<AppState>,
       private router: Router,
       private utils:TypesUtilsService,
       private configService: ConfigService
       ) { }
    // Authentication/Authorization
    login(email: string, password: string): Observable<ResponseBody> {
        let reqHeader = new HttpHeaders()
            .set('Auth', 'False')
        return this.http.post<ResponseBody>(environment.api.host + '/auth/login', { 'username': email, 'password': password }, { headers: reqHeader })
            .pipe(
                tap(body => {
                    // user.result.id;
                    body.result = new User().deserialize(body.result, {
                        tranform: {
                            'accessToken': 'token',
                            'email': 'username'
                        }
                    });
                }),
            );
    }

    newLogin(username: string, password: string): Observable<Response> {
        let reqHeader = new HttpHeaders()
            .set('Auth', 'False')
        return this.http.post<Response>(environment.api.host + '/auth/login', { 'username': username, 'password': password }, { headers: reqHeader })
            .pipe(
                tap(body => {
                    // user.result.id;
                    body.data = new User().deserialize(body.data, {
                        tranform: {
                            'accessToken': 'token',
                            'username': 'username'
                        }
                    });
                }),
            );
    }

    getAllPermission(option): Observable<ResponseBody> {
        let httpHeaders = new HttpHeaders();

        return this.http.get<ResponseBody>(environment.api.host + "/permissions/search", {
            headers: httpHeaders,
            params:option
        });
    }
    // test() {
    //     return this.http.get(environment.api.host + '/users/profile').toPromise();
    // }
    getUserByToken(option?: BaseOptionAPI): Observable<Response> {
        const userToken = localStorage.getItem(environment.authTokenKey);
        let httpHeaders = new HttpHeaders();

        return this.http.get<Response>(environment.api.host + "/users/profile", { headers: httpHeaders })
            .pipe(
                tap(body => {
                    if (body.errorCode == '401') {
                        this.store.dispatch(new Logout());
                        this.router.navigate(['/auth/login']);
                    }
                    body.data = new User().deserialize(body.data, {
                        tranform: {
                            'fullname': 'name'
                        }
                    });
                    this.configService.initConfig();
                    this.profileLoaded.emit( body.data);
                })
            );
    }

    profile(option?: BaseOptionAPI): Observable<Response> {
        let httpHeaders = new HttpHeaders();
        return this.http.get<Response>(environment.api.host + "/users/profile", { headers: httpHeaders })
            .pipe(
                tap(body => {
                    if (body.errorCode == '401') {
                        this.store.dispatch(new Logout());
                        this.router.navigate(['/auth/login']);
                    }
                    body.data = new User().deserialize(body.data, {
                        tranform: {
                            'fullname': 'name'
                        }
                    });
                    this.profileLoaded.emit( body.data);
                })
            );
    }

    register(user: User): Observable<any> {
        return this.http.post<User>(API_USERS_URL, user)
            .pipe(
                map((res: User) => {
                    return res;
                }),
                catchError(err => {
                    return null;
                })
            );
    }

    /*
     * Submit forgot password request
     *
     * @param {string} email
     * @returns {Observable<any>}
     */
    requestPassword(params: any,option?: BaseOptionAPI): Observable<any> {
        let reqHeader = new HttpHeaders().set('Auth', 'False');
        return  this.http.post(environment.api.host+'/auth/forgot-password',params,{
            params:this.utils.processBassOptionApi(option),
            headers:reqHeader
          });
    }

    resetPassword(params,option?: BaseOptionAPI): Observable<any> {
        let reqHeader = new HttpHeaders().set('Auth', 'False');
        return  this.http.post(environment.api.host+'/auth/reset-password',params,{
            params:this.utils.processBassOptionApi(option),
            headers:reqHeader
        });
    }

    changePassword(params,option?: BaseOptionAPI): Observable<any> {
        return  this.http.put(environment.api.host+'/users/change-password',params,{
            params:this.utils.processBassOptionApi(option),
        });
    }

    getAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(API_USERS_URL);
    }

    getUserById(userId: number): Observable<User> {
        return this.http.get<User>(API_USERS_URL + `/${userId}`);
    }


    // DELETE => delete the user from the server
    deleteUser(userId: number) {
        const url = `${API_USERS_URL}/${userId}`;
        return this.http.delete(url);
    }

    // UPDATE => PUT: update the user on the server
    updateUser(_user: User): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_USERS_URL, _user, { headers: httpHeaders });
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: User): Observable<User> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<User>(API_USERS_URL, user, { headers: httpHeaders });
    }

    // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
    // items => filtered/sorted result
    findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<QueryResultsModel>(API_USERS_URL + '/findUsers', queryParams, { headers: httpHeaders });
    }
    // getAllPermissions(): Observable<Permission[]> {
    //     let permissionResult= this.http.get<Permission[]>(API_USERS_URL+'/permisisons');
    //     permissionResult.subscribe(x=>console.log(x));
    //     return permissionResult;
    // }

    private permissionsSubject = new BehaviorSubject<any>({});
    permissionsObservable$ = this.permissionsSubject.asObservable();
    async getAllPermissionStream() {
        let user: any = this.store.pipe(select(currentUser));
        if (user) {
            user.subscribe((data:any) => {
                if (data) {
                    let permissions = data.permission.split(',').map(function (value, key) {
                        return {id: key + 1, name: value, title: value};
                    })
                    this.permissionsSubject.next(permissions);
                }
            })
        }
    }

    // Permission
    getAllPermissions(): Observable<Permission[]> {
        let user:any = this.store.pipe(select(currentUser));
        let ObPermissions: Observable<Permission[]> = of([]);
        let permissionStr = '';
        if (user) {
            select(user.subscribe(function (data) {
                if (data) permissionStr = data.permission
            }
            ).unsubscribe());
            let permissions = permissionStr.split(',').map(function (value, key) {
                let obj = {
                    id: key + 1,
                    name: value,
                    title: value,
                };
                return new Permission().deserialize(obj, { tranform: {} });
            });
            ObPermissions = of(permissions);
        }
        return ObPermissions;
        // return this.http.get<Permission[]>(API_PERMISSION_URL);
    }

    //api permission
    searchPermissions(params:  any | undefined, option?: any): Observable<Response>{
        return this.http.post<Response>(environment.api.host+'/permissions/search', option, { params: params});
    }

    getPermissionById(permissionId: number): Observable<ResponseBody>{
        return this.http.get<ResponseBody>(environment.api.host+'/permissions' + `/${permissionId}`);
    }

    getListPermissionGroup(): Observable<Permission> {
        return  this.http.get<Permission>(environment.api.host+'/permissions/get-list-group-name');
    }

    updatePermission(PermisionId :number,Permission: Permission): Observable<Response>{
        return  this.http.put<Response>(environment.api.host+'/permissions/'+PermisionId,Permission)
    }

    deletePermission(permissionId: number): Observable<ResponseBody>{
    return  this.http.delete<ResponseBody>(environment.api.host+'/permissions/'+permissionId);
    }

    addPermission(Permission: Permission, option?: BaseOptionAPI){
        return  this.http.post(environment.api.host+'/permissions',Permission,{
            params:this.utils.processBassOptionApi(option),
          });
        }

    getRolePermissions(roleId: number): Observable<Permission[]> {
        return this.http.get<Permission[]>(API_PERMISSION_URL + '/getRolePermission?=' + roleId);
    }
    // api role
    searchRoles(option: RoleSearchRequest, params:  any | undefined):Observable<Response>{
        return this.http.post<Response>(environment.api.host+'/roles/search', option, { params: params});
    }

    getListRoles(params:  any | undefined, option:any):Observable<Response>{
        return this.http.post<Response>(environment.api.host+'/roles/search', option, { params: params});
    }

    // getRole(roleId : number){
    //     return this.http.get(environment.api.host+'/roles' + `/${roleId}`);
    // }

    addRole(role : Role, option?: BaseOptionAPI):Observable<Response>{
        return this.http.post<Response>(environment.api.host+'/roles', role,{
            params:this.utils.processBassOptionApi(option),
          });
    }

    updateRoleById(role : Role, option?: BaseOptionAPI):Observable<Response>{
    return this.http.put<Response>(environment.api.host+'/roles/'+role.id, role,{
        params:this.utils.processBassOptionApi(option),
      });
    }

    deleteRoleById(roleId: number, option?: BaseOptionAPI):Observable<Response>{
    return  this.http.delete<Response>(environment.api.host+'/roles/'+roleId,{
        params:this.utils.processBassOptionApi(option),
      });
    }

    // Roles
    getAllRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(API_ROLES_URL);
    }

    getRoleById(roleId: number):Observable<Response> {
        return this.http.get<Response>(environment.api.host+'/roles' + `/${roleId}`);
    }

    // CREATE =>  POST: add a new role to the server
    createRole(role: Role): Observable<Role> {
        // Note: Add headers if needed (tokens/bearer)
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<Role>(API_ROLES_URL, role, { headers: httpHeaders });
    }

    // UPDATE => PUT: update the role on the server
    updateRole(role: Role): Observable<any> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.put(API_ROLES_URL, role, { headers: httpHeaders });
    }

    // DELETE => delete the role from the server
    deleteRole(roleId: number): Observable<Role> {
        const url = `${API_ROLES_URL}/${roleId}`;
        return this.http.delete<Role>(url);
    }

    // Check Role Before deletion
    isRoleAssignedToUsers(roleId: number): Observable<boolean> {
        return this.http.get<boolean>(API_ROLES_URL + '/checkIsRollAssignedToUser?roleId=' + roleId);
    }

    findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
        // This code imitates server calls
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        return this.http.post<QueryResultsModel>(API_ROLES_URL + '/findRoles', queryParams, { headers: httpHeaders });
    }

    /*
     * Handle Http operation that failed.
     * Let the app continue.
   *
   * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
